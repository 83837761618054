import * as React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Faq from "../components/Faq/Faq"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Button from "../components/Button/Button"



const ProductTemplateStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: var(--sectionPadding) var(--borderSpacing);
  background-color: #fff;

  .column {
    flex: 0 0 100%;

    @media (min-width: 768px) {
      flex-basis: 50%;

      &:nth-child(1) {
        padding-right: 20px;
      }

      &:nth-child(2) {
        padding-left: 20px;
      }

      > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    > * {
      &:first-child {
        margin-top: 0;
      }
    }
  }
`





const productTemplate = ({ data }) => {
  const { name, price, excerpt, productDescription, images, faq } = data.product

  const [mainImage, ...productImages] = images
  const image = getImage(mainImage)
  const image2 = getImage(images[1])
  const image3 = getImage(images[2])
  const image4 = getImage(images[3])
  const image5 = getImage(images[4])
  const LastImage = getImage(productImages)
  



  return (
    <>
      <Seo title={name} />
      <Layout>
      
        <BannerModule
          title={name}
          price={price}
          subTitle={excerpt}
          enquire={true}
        >
          <GatsbyImage
            className="banner__image"
            image={image}
            alt="Banner Image"
          />
        </BannerModule>
        
        <Faq title={"Галерия"} description= 
              {
                <div>
                  <GatsbyImage
                    className="extra__image"
                    image={image2}
                    alt="Image02"
                  />
                  <GatsbyImage
                    className="extra2__image"
                    image={image3}
                    alt="Image03"
                  />
                  <GatsbyImage
                    className="extra3__image"
                    image={image4}
                    alt="Image04"
                  />
                  <GatsbyImage
                    className="extra4__image"
                    image={image5}
                    alt="Image05"
                  />
                  <GatsbyImage
                    className="extra5__image"
                    image={LastImage}
                    alt="Image05"
                  />
              
              </div>
              }
          />
        <ProductTemplateStyles> 
          <div className="column">{renderRichText(productDescription)}</div>
          <div className="column">
            {faq.map((item, index) => {
              return (
                <Faq
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              )
            })}
          </div>
        </ProductTemplateStyles>
        
        <ProductTemplateStyles> 
          <div className="blogsingle__back">
              <Button text="Към продукти" as={Link} to="/products" />
          </div>
        </ProductTemplateStyles>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    product: contentfulProducts(slug: { eq: $slug }) {
      name
      price
      excerpt
      productDescription {
        raw
      }
      faq {
        title
        description
      }
      images {
        gatsbyImageData(width: 2000, formats: [AUTO, WEBP])
      }
    }
  }
`

export default productTemplate
